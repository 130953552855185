<template>
  <v-footer
    color="black"
    style="width: 100%;"
  >
    <v-card
      flat
      tile
      width="100%"
      height="100%"
      class="black lighten-1"
    >
      <v-container
        style="
          margin-top: 12em;
          margin-bottom: 12em;
        "
      >
        <v-layout wrap justify-space-around fill-height style="width: 100%;">
          <v-flex xs12 sm12 md3>
            <v-flex xs12>
              <v-img
                :src="require('@/assets/logo.png')"
                class="my-3"
                contain
                height="100%"
              ></v-img>
            </v-flex>
            <v-layout justify-center>

              <v-btn fab color="secondary" class="mr-2 mt-2" target="_blank" href="https://www.facebook.com/conectarevendasapp?mibextid=ZbWKwL">
                <font-awesome-icon class="fa-2x" :icon="['fab', 'facebook']"/>
              </v-btn>
              <v-btn fab color="secondary" class="mr-2 mt-2" target="_blank" href="https://youtube.com/@ConectaRevendas?si=0uwTD-stAWr9Rk0U">
                <font-awesome-icon class="fa-2x" :icon="['fab', 'youtube']" />
              </v-btn>
              <v-btn fab color="secondary" class="mr-2 mt-2" target="_blank" href="http://wa.me/5584996883656">
                <font-awesome-icon class="fa-2x" :icon="['fab', 'whatsapp']" />
              </v-btn>
              <v-btn fab color="secondary" class="mr-2 mt-2" target="_blank" href="https://www.instagram.com/conectarevendas?igsh=MTRjN3FvbzY5ZDNxYw==">
                <font-awesome-icon class="fa-2x" :icon="['fab', 'instagram']" />
              </v-btn>
              <v-btn fab color="secondary" class="mt-2" target="_blank" href="https://www.linkedin.com/company/conecta-revendas/about/?viewAsMember=true">
                <font-awesome-icon class="fa-2x" :icon="['fab', 'linkedin']" />
              </v-btn>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md3>
            <v-list style="background-color: black;" dark>
              <v-subheader class="secondary--text text-h6">
                <b>Links Rápidos</b>
              </v-subheader>
              <v-list-item href="https://conectarevendas.com.br/" class="hover-secondary">
                <v-list-item-content>
                  <v-list-item-title>
                    Página Inicial
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group
                no-action
                @click="setActiveGroup"
                :class="{ 'active-secondary': activeGroup, 'hover-secondary': true }"
              >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title href="https://conectarevendas.com.br/blog/" style="color: white;">
                        Blog
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item class="hover-secondary" href="https://conectarevendas.com.br/category/conecta-revendas/">
                    <v-list-item-content>
                      <v-list-item-title>
                        Conecta Revendas
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="hover-secondary" href="https://conectarevendas.com.br/category/curiosidades/">
                    <v-list-item-content>
                      <v-list-item-title>
                        Curiosidades
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="hover-secondary" href="https://conectarevendas.com.br/category/carros/">
                    <v-list-item-content>
                      <v-list-item-title>
                        Carros
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="hover-secondary" href="https://conectarevendas.com.br/category/motos/">
                    <v-list-item-content>
                      <v-list-item-title>
                        Motos
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="hover-secondary" href="https://conectarevendas.com.br/category/dicas/">
                    <v-list-item-content>
                      <v-list-item-title>
                        Dicas
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              <v-list-item href="https://conectarevendas.com.br/duvidas/" class="hover-secondary">
                <v-list-item-content>
                  <v-list-item-title>
                    Perguntas Frequentes
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-flex>
          <v-flex xs12 sm12 md3>
            <v-list style="background-color: black;" dark>
              <v-subheader class="secondary--text text-h6">
                <b>Links Úteis</b>
              </v-subheader>
              <v-list-item href="https://conectarevendas.com.br/politica-de-privacidade/" class="hover-secondary">
                <v-list-item-content>
                  <v-list-item-title>
                    Política de Privacidade
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item href="https://conectarevendas.com.br/duvidas/" class="hover-secondary">
                <v-list-item-content>
                  <v-list-item-title>
                    Perguntas Frequentes
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item href="https://conectarevendas.com.br/termos-de-uso/" class="hover-secondary">
                <v-list-item-content>
                  <v-list-item-title>
                    Termos de Uso
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item href="https://conectarevendas.com.br/contato/" class="hover-secondary">
                <v-list-item-content>
                  <v-list-item-title>
                    Contato
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-flex>
          <v-flex xs12 sm12 md3>
            <h3 class="secondary--text text-h6 mt-4">Contatos</h3>
            <h4 class="mb-4 contatos">Whatsapp:</h4>
            <h4 class="mb-4 contatos">(84) 99688-3656</h4>
            <h4 class="mb-4 contatos">Email:</h4>
            <h4 class="mb-4 contatos">contato@conectarevendas.com.br</h4>
            <v-btn class="mt-4 black--text" large color="secondary" block>
              Fale Conosco
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider color="white"></v-divider>
      <h4 class="white--text text-center mt-3">Copyright ⓒ 2024 - Todos os Direitos Reservados</h4>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    activeGroup: false
  }),
  methods: {
    setActiveGroup () {
      this.activeGroup = !this.activeGroup
    }
  }
}
</script>

<style scoped>
  h4 {
    color: white;
  }
  .hover-secondary:hover {
    background-color: var(--v-secondary-base) !important;
  }
  .active-secondary {
    background: linear-gradient(to bottom, var(--v-secondary-base) 3em, black 1em) !important;
    color: white !important;
  }
  .contatos {
    margin-top: 1.3em;
    font-weight: 400;
    font-size: 1.1em;
  }
</style>
