<template>
  <v-img
    :src="require('@/assets/background.svg')"
    cover
    :max-width="'100%'"
    :max-height="'100vh'"
    position="top center"
  >
    <v-container
      fluid
      fill-height
      class="pa-0"
      style="
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        width: 100%;
      "
    >
      <app-bar/>
      <v-main app style="height: 100%; width: 100%;">
        <router-view>
        </router-view>
      </v-main>
    </v-container>
  </v-img>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
export default {
  components: { AppBar },
  data: () => ({})
}
</script>

<style>
  @font-face {
    font-family: 'Harabara Mais';
    src: url('../../assets/fonts/HarabaraMais.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Harabara';
    src: url('../../assets/fonts/harabara.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Aileron';
    src: url('../../assets/fonts/aileron/Aileron-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Aileron';
    src: url('../../assets/fonts/aileron/Aileron-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Aileron';
    src: url('../../assets/fonts/aileron/Aileron-Italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Aileron';
    src: url('../../assets/fonts/aileron/Aileron-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
  }

  .aileron-regular {
    font-family: 'Aileron', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .aileron-bold {
    font-family: 'Aileron', sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .aileron-italic {
    font-family: 'Aileron', sans-serif;
    font-weight: 400;
    font-style: italic;
  }

  .aileron-bold-italic {
    font-family: 'Aileron', sans-serif;
    font-weight: 700;
    font-style: italic;
  }

  body {
    font-family: 'harabara', sans-serif !important;
    font-weight: 400;
  }
  .v-toolbar__title {
    font-family: 'harabara', sans-serif !important;
    font-weight: 900;
  }
  .allcenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .v-responsive__content {
    widows: 100%;
  }
</style>
