<template>
  <v-card
    color="primary"
    class="ma-2"
    :style="{
      'border': '0.3em solid #021e49 !important',
      'border-radius': '0em',
      'color': 'white !important',
      'width': card_width,
      'min-width': card_width
    }"
  >
    <v-layout justify-center class="mb-2">
      <v-carousel
        v-if="fotos.length > 0 && !loading"
        hide-delimiter-background
        height="16em"
        class="ma-0 pa-0"
        style="
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        "
      >
        <v-carousel-item
          v-for="(item,i) in fotos"
          :key="i"
          style="height: 17em; max-height: 17em; width: auto;"
        >
          <img v-if="item && item.url" :src="item.url" style="height: 17em; max-height: 17em; width: auto;">
        </v-carousel-item>
      </v-carousel>
      <v-card
        v-else
        style="
          width: 100%;
          height: 16em;
          max-height: 17em;
          border-radius: 0%;
          justify-content: center;
          align-items: center;
          display: flex;
        "
        color="transparent"
        elevation="0"
      >
        <font-awesome-icon class="fa-6x" :icon="['fas', 'image']" />
      </v-card>
    </v-layout>
    <v-card-text class="white--text pb-0 mb-0">
      <v-layout wrap justify-space-between>
        <v-flex xs7 sm6 style="height: 3em;">
          <h4>({{ veiculo.marca }}) {{ veiculo.modelo.split(' ')[0] }}</h4>
        </v-flex>
        <v-flex xs3 sm4>
          <h5 class="text-end">{{ veiculo.endereco }}</h5>
        </v-flex>
        <v-flex xs7 class="mt-3 mb-3">
          <h3>{{ veiculo.valor | currency({ fractionCount: 2 }) }}</h3>
        </v-flex>
        <v-flex xs3 class="mt-3 mb-3">
          <h6 class="text-center">COD: {{ veiculo.id_veiculo }}</h6>
        </v-flex>
        <v-flex xs4>
          <h6 class="text-center">{{ veiculo.quilometragem }} KM</h6>
        </v-flex>
        <v-flex xs4>
          <h6 class="text-center">{{ veiculo.ano_fabricacao }}</h6>
        </v-flex>
        <v-flex xs4>
          <h6 class="text-center">{{ veiculo.usado ? 'Usado': 'Novo' }}</h6>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="pa-0 ma-0">
      <v-btn block color="rgb(253, 231, 80)" @click="$emit('visualizar')">Detalhes</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['veiculo'],
  data: () => ({
    fotos: [],
    loading: false
  }),
  computed: {
    card_width () {
      if (this.$vuetify.breakpoint.xs) return '23em'
      else if (this.$vuetify.breakpoint.sm) return '22em'
      else if (this.$vuetify.breakpoint.md) return '17em'
      return '20em'
    }
  },
  async beforeMount () {
    this.loading = true
    this.fotos = []
    if (this.veiculo.fotos && this.veiculo.fotos.length > 0) {
      this.veiculo.fotos.map((foto) => {
        this.doesImageExist(foto.url).then(resposta => {
          if (resposta) this.fotos.push(foto)
        })
      })
    } else {
      if (this.veiculo.imagem_frontal) {
        await this.doesImageExist(this.veiculo.imagem_frontal).then(resposta => {
          if (resposta) this.fotos.push({ url: this.veiculo.imagem_frontal })
        })
      } else if (this.veiculo.imagem_traseira) {
        await this.doesImageExist(this.veiculo.imagem_traseira).then(resposta => {
          if (resposta) this.fotos.push({ url: this.veiculo.imagem_traseira })
        })
      } else if (this.veiculo.imagem_lateral_e) {
        await this.doesImageExist(this.veiculo.imagem_lateral_e).then(resposta => {
          if (resposta) this.fotos.push({ url: this.veiculo.imagem_lateral_e })
        })
      } else if (this.veiculo.imagem_lateral_d) {
        await this.doesImageExist(this.veiculo.imagem_lateral_d).then(resposta => {
          if (resposta) this.fotos.push({ url: this.veiculo.imagem_lateral_d })
        })
      } else if (this.veiculo.imagem_interior) {
        await this.doesImageExist(this.veiculo.imagem_interior).then(resposta => {
          if (resposta) this.fotos.push({ url: this.veiculo.imagem_interior })
        })
      }
    }
    this.loading = false
  },
  methods: {
    async doesImageExist (url) {
      return new Promise((resolve) => {
        const img = new Image()
        img.src = url
        img.onload = () => resolve(true)
        img.onerror = () => resolve(false)
      })
    }
  }
}
</script>

<style scoped>
  .v-card {
    font-family: 'Aileron', sans-serif !important;
    font-weight: 400;
  }
</style>
