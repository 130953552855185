<template>
  <v-layout
    align-space-around
    justify-start
    class="mt-4"
    style="width: 100%; height: 100%;"
    wrap
    v-if="veiculo"
  >
    <v-container  style="margin-bottom: 10em;" class="font-aileron">
      <v-carousel
        height="30em"
        class="ma-0 pa-0"
        hide-delimiter-background
        v-if="!loading"
        style="
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        "
      >
        <v-carousel-item
          v-for="(item,i) in fotos"
          :key="i"
          :src="item.url"
          reverse-transition="fade-transition"
          transition="fade-transition"
          cover
          style="height: 30em; width: 100%;"
        ></v-carousel-item>
      </v-carousel>
      <v-card class="mt-4 pa-4 font-aileron">
        <v-card-title>
          {{ veiculo.marca }} {{ veiculo.modelo }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="font-aileron">
          <v-layout wrap justify-space-between>
            <v-flex xs4 class="mb-4 text-subtitle-1 text-center">
              <v-icon color="secondary" size="2em" class="mr-2">location_on</v-icon><b>{{ veiculo.endereco }}</b>
            </v-flex>
            <v-flex xs4 class="mb-4 text-subtitle-1 text-center">
              <v-icon color="secondary" size="2em" class="mr-2">payments</v-icon>
              <b>{{ veiculo.valor | currency({ fractionCount: 2 }) }}</b>
            </v-flex>
            <v-flex xs4 class="mb-4 text-subtitle-1 text-center">
              <v-icon color="secondary" size="2em" class="mr-2">calendar_month</v-icon>
              <b>{{ veiculo.data_avenda | formatDate('DD/MM/YYYY') }}</b>
            </v-flex>
            <v-divider></v-divider>
            <v-flex xs5 class="mt-4">
              CÂMBIO: <b>{{ cambios[veiculo.cambio - 1].toUpperCase() }}</b>
            </v-flex>
            <v-flex xs5 class="mt-4">
              DIREÇÃO: <b>{{ direcoes[veiculo.direcao - 1].toUpperCase() }}</b>
            </v-flex>
            <v-flex xs5>
              ANO: <b>{{ veiculo.ano_fabricacao }}</b>
            </v-flex>
            <v-flex xs5>
              QUILOMETRAGEM: <b>{{ veiculo.quilometragem }} KM</b>
            </v-flex>
            <v-flex xs5>
              COR: <b>{{ veiculo.cor_texto }}</b>
            </v-flex>
            <v-flex xs5>
              PORTAS: <b>{{ veiculo.qtd_portas }}</b>
            </v-flex>
            <v-flex xs5>
              COMBUSTÍVEL: <b>{{ veiculo.qtd_portas }}</b>
            </v-flex>
            <v-flex xs5>
              USADO: <b>{{ veiculo.usado ? 'SIM' : 'NÃO' }}</b>
            </v-flex>
            <v-flex xs5>
              REVISÕES FEITAS: <b>{{ veiculo.revisoes_feitas ? 'SIM' : 'NÃO' }}</b>
            </v-flex>
            <v-flex xs5>
              COM GARANTIA: <b>{{ veiculo.com_garantia ? 'SIM' : 'NÃO' }}</b>
            </v-flex>
            <v-flex xs5>
              UNICO DONO: <b>{{ veiculo.unico_dono ? 'SIM' : 'NÃO' }}</b>
            </v-flex>
            <v-flex xs5>
              CHAVE RESERVA: <b>{{ veiculo.chave_reserva ? 'SIM' : 'NÃO' }}</b>
            </v-flex>
            <v-flex xs5>
              DE LEILÃO: <b>{{ veiculo.de_leilao ? 'SIM' : 'NÃO' }}</b>
            </v-flex>
            <v-flex xs5>
              COM MULTA: <b>{{ veiculo.com_multa ? 'SIM' : 'NÃO' }}</b>
            </v-flex>
            <v-flex xs5>
              QUITADO: <b>{{ veiculo.quitando ? 'NÃO' : 'SIM' }}</b>
            </v-flex>
            <v-flex xs5>
              FINAL DA PLACA: <b>{{ veiculo.final_placa }}</b>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <v-card class="mt-4 mb-4 pa-4 font-aileron" v-if="opcionais.length > 0">
        <v-card-title>OPCIONAIS:</v-card-title>
        <v-card-text>
          <v-layout wrap justify-start>
            <v-flex xs4 v-for="(item, index) in veiculo.opcionais" :key="index" style="font-size: 1.2em;">
              <b>{{ item.nome }}</b>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <v-card class="mt-4 mb-4 pa-4 font-aileron" v-if="veiculo.url_laudo_cautelar">
        <v-card-title>VISTORIADO:</v-card-title>
        <v-card-text class="justify-center text-center">
          <img :src="veiculo.url_laudo_cautelar" style="width: 80%;">
        </v-card-text>
      </v-card>
      <v-card class="mt-4 mb-4 pa-4 font-aileron" v-if="veiculo.empresa">
        <v-card-title>Sobre o vendedor</v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs1>
              <img :src="veiculo.empresa.url_logo" style="border-radius: 5em; height: 7em;" class="ma-2">
            </v-flex>
            <v-flex xs9 class="ml-4">
              <h2>{{ veiculo.empresa.nome_fantasia }}</h2>
              <v-btn
                color="primary"
                class="mt-3"
                @click="abrirVisualizar(veiculo.empresa)"
              >Ver todos os carros deste vendedor</v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-container>
    <Footer></Footer>
  </v-layout>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { veiculoDao } from '@/api'
export default {
  components: { Footer },
  data: () => ({
    veiculo: null,
    cambios: ['Manual', 'Automático', 'Automatizado', 'CVT'],
    direcoes: ['Mecânica', 'Hidráulica', 'Elétrica', 'Eletro-hidráulica'],
    opcionais: [],
    fotos: [],
    loading: true
  }),
  async beforeMount () {
    this.fotos = []
    if (this.$route.params) {
      if (this.$route.params.id_veiculo) this.veiculo = this.$route.params
      else {
        await this.pegarVeiculos(localStorage.getItem('veiculo'))
      }
      if (this.veiculo) {
        if (this.veiculo.opcionais && this.veiculo.opcionais.length > 0) {
          this.opcionais = this.veiculo.opcionais
        } else {
          if (this.veiculo.vidro_eletrico) {
            this.opcionais.push({ nome: 'Vidro Eletrico' })
          }

          if (this.veiculo.alarme) {
            this.opcionais.push({ nome: 'Alarme' })
          }

          if (this.veiculo.air_bag) {
            this.opcionais.push({ nome: 'Alarme' })
          }

          if (this.veiculo.som) {
            this.opcionais.push({ nome: 'Som' })
          }

          if (this.veiculo.trava_eletrica) {
            this.opcionais.push({ nome: 'Trava Eletríca' })
          }

          if (this.veiculo.sensor_re) {
            this.opcionais.push({ nome: 'Sensor de ré' })
          }

          if (this.veiculo.ar) {
            this.opcionais.push({ nome: 'Ar condicionado' })
          }

          if (this.veiculo.camera_re) {
            this.opcionais.push({ nome: 'Camera de ré' })
          }

          if (this.veiculo.air_bag_passageiro) {
            this.opcionais.push({ nome: 'Air bag passageiro' })
          }

          if (this.veiculo.volante_regulagem_altura) {
            this.opcionais.push({ nome: 'Volante com regulagem de altura' })
          }

          if (this.veiculo.bluetooth) {
            this.opcionais.push({ nome: 'Bluetooth' })
          }

          if (this.veiculo.vidro_eletrico_traseiro) {
            this.opcionais.push({ nome: 'Vidro eletrico traseiro' })
          }

          if (this.veiculo.vidro_eletrico_dianteiro) {
            this.opcionais.push({ nome: 'Vidro eletrico dianteiro' })
          }

          if (this.veiculo.rodas_liga_leve) {
            this.opcionais.push({ nome: 'Rodas de liga leve' })
          }

          if (this.veiculo.kit_multimidia) {
            this.opcionais.push({ nome: 'Kit multimidia' })
          }

          if (this.veiculo.kit_gnv) {
            this.opcionais.push({ nome: 'Kit GNV' })
          }

          if (this.veiculo.computador_bordo) {
            this.opcionais.push({ nome: 'Computador de bordo' })
          }

          if (this.veiculo.bancos_couro) {
            this.opcionais.push({ nome: 'Bancos de couro' })
          }

          if (this.veiculo.air_bag_lateral) {
            this.opcionais.push({ nome: 'Air bag lateral' })
          }

          if (this.veiculo.retrovisores_eletricos) {
            this.opcionais.push({ nome: 'Retrovisores eletricos' })
          }

          if (this.veiculo.freios_abs) {
            this.opcionais.push({ nome: 'Freio ABS' })
          }

          if (this.veiculo.radio) {
            this.opcionais.push({ nome: 'Rádio' })
          }

          if (this.veiculo.entrada_usb) {
            this.opcionais.push({ nome: 'Entrada USB' })
          }

          if (this.veiculo.air_bag_duplo) {
            this.opcionais.push({ nome: 'Air bag duplo' })
          }

          if (this.veiculo.porta_copos) {
            this.opcionais.push({ nome: 'Porta copos' })
          }

          if (this.veiculo.cd_player) {
            this.opcionais.push({ nome: 'CD player' })
          }

          if (this.veiculo.distribuicao_eletronica_frenagem) {
            this.opcionais.push({ nome: 'EBD' })
          }

          if (this.veiculo.desembaçador_traseiro) {
            this.opcionais.push({ nome: 'Desembaçador traseiro' })
          }

          if (this.veiculo.blindado) {
            this.opcionais.push({ nome: 'Blidagem' })
          }

          if (this.veiculo.gps) {
            this.opcionais.push({ nome: 'GPS' })
          }

          if (this.veiculo.escapamento_esportivo) {
            this.opcionais.push({ nome: 'Escapamento esportivo' })
          }

          if (this.veiculo.contra_peso_guidon) {
            this.opcionais.push({ nome: 'Contro peso no guidon' })
          }

          if (this.veiculo.bau) {
            this.opcionais.push({ nome: 'Baú' })
          }

          if (this.veiculo.farois_neblina) {
            this.opcionais.push({ nome: 'Farois de neblina' })
          }
        }

        if (this.veiculo.fotos && this.veiculo.fotos.length > 0) {
          this.veiculo.fotos.map((foto) => {
            this.doesImageExist(foto.url).then(resposta => {
              if (resposta) this.fotos.push(foto)
            })
          })
        } else {
          if (this.veiculo.imagem_frontal) {
            this.doesImageExist(this.veiculo.imagem_frontal).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_frontal })
            })
          } else if (this.veiculo.imagem_traseira) {
            this.doesImageExist(this.veiculo.imagem_traseira).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_traseira })
            })
          } else if (this.veiculo.imagem_lateral_e) {
            this.doesImageExist(this.veiculo.imagem_lateral_e).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_lateral_e })
            })
          } else if (this.veiculo.imagem_lateral_d) {
            this.doesImageExist(this.veiculo.imagem_lateral_d).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_lateral_d })
            })
          } else if (this.veiculo.imagem_interior) {
            this.doesImageExist(this.veiculo.imagem_interior).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_interior })
            })
          }
        }
      }
    }
    this.loading = false
  },
  watch: {
    veiculo (val) {
      if (val) {
        this.fotos = []
        if (this.veiculo.opcionais && this.veiculo.opcionais.length > 0) {
          this.opcionais = this.veiculo.opcionais
        } else {
          if (this.veiculo.vidro_eletrico) {
            this.opcionais.push({ nome: 'Vidro Eletrico' })
          }

          if (this.veiculo.alarme) {
            this.opcionais.push({ nome: 'Alarme' })
          }

          if (this.veiculo.air_bag) {
            this.opcionais.push({ nome: 'Alarme' })
          }

          if (this.veiculo.som) {
            this.opcionais.push({ nome: 'Som' })
          }

          if (this.veiculo.trava_eletrica) {
            this.opcionais.push({ nome: 'Trava Eletríca' })
          }

          if (this.veiculo.sensor_re) {
            this.opcionais.push({ nome: 'Sensor de ré' })
          }

          if (this.veiculo.ar) {
            this.opcionais.push({ nome: 'Ar condicionado' })
          }

          if (this.veiculo.camera_re) {
            this.opcionais.push({ nome: 'Camera de ré' })
          }

          if (this.veiculo.air_bag_passageiro) {
            this.opcionais.push({ nome: 'Air bag passageiro' })
          }

          if (this.veiculo.volante_regulagem_altura) {
            this.opcionais.push({ nome: 'Volante com regulagem de altura' })
          }

          if (this.veiculo.bluetooth) {
            this.opcionais.push({ nome: 'Bluetooth' })
          }

          if (this.veiculo.vidro_eletrico_traseiro) {
            this.opcionais.push({ nome: 'Vidro eletrico traseiro' })
          }

          if (this.veiculo.vidro_eletrico_dianteiro) {
            this.opcionais.push({ nome: 'Vidro eletrico dianteiro' })
          }

          if (this.veiculo.rodas_liga_leve) {
            this.opcionais.push({ nome: 'Rodas de liga leve' })
          }

          if (this.veiculo.kit_multimidia) {
            this.opcionais.push({ nome: 'Kit multimidia' })
          }

          if (this.veiculo.kit_gnv) {
            this.opcionais.push({ nome: 'Kit GNV' })
          }

          if (this.veiculo.computador_bordo) {
            this.opcionais.push({ nome: 'Computador de bordo' })
          }

          if (this.veiculo.bancos_couro) {
            this.opcionais.push({ nome: 'Bancos de couro' })
          }

          if (this.veiculo.air_bag_lateral) {
            this.opcionais.push({ nome: 'Air bag lateral' })
          }

          if (this.veiculo.retrovisores_eletricos) {
            this.opcionais.push({ nome: 'Retrovisores eletricos' })
          }

          if (this.veiculo.freios_abs) {
            this.opcionais.push({ nome: 'Freio ABS' })
          }

          if (this.veiculo.radio) {
            this.opcionais.push({ nome: 'Rádio' })
          }

          if (this.veiculo.entrada_usb) {
            this.opcionais.push({ nome: 'Entrada USB' })
          }

          if (this.veiculo.air_bag_duplo) {
            this.opcionais.push({ nome: 'Air bag duplo' })
          }

          if (this.veiculo.porta_copos) {
            this.opcionais.push({ nome: 'Porta copos' })
          }

          if (this.veiculo.cd_player) {
            this.opcionais.push({ nome: 'CD player' })
          }

          if (this.veiculo.distribuicao_eletronica_frenagem) {
            this.opcionais.push({ nome: 'EBD' })
          }

          if (this.veiculo.desembaçador_traseiro) {
            this.opcionais.push({ nome: 'Desembaçador traseiro' })
          }

          if (this.veiculo.blindado) {
            this.opcionais.push({ nome: 'Blidagem' })
          }

          if (this.veiculo.gps) {
            this.opcionais.push({ nome: 'GPS' })
          }

          if (this.veiculo.escapamento_esportivo) {
            this.opcionais.push({ nome: 'Escapamento esportivo' })
          }

          if (this.veiculo.contra_peso_guidon) {
            this.opcionais.push({ nome: 'Contro peso no guidon' })
          }

          if (this.veiculo.bau) {
            this.opcionais.push({ nome: 'Baú' })
          }

          if (this.veiculo.farois_neblina) {
            this.opcionais.push({ nome: 'Farois de neblina' })
          }
        }

        if (this.veiculo.fotos && this.veiculo.fotos.length > 0) {
          this.veiculo.fotos.map((foto) => {
            this.doesImageExist(foto.url).then(resposta => {
              if (resposta) this.fotos.push(foto)
            })
          })
        } else {
          if (this.veiculo.imagem_frontal) {
            this.doesImageExist(this.veiculo.imagem_frontal).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_frontal })
            })
          } else if (this.veiculo.imagem_traseira) {
            this.doesImageExist(this.veiculo.imagem_traseira).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_traseira })
            })
          } else if (this.veiculo.imagem_lateral_e) {
            this.doesImageExist(this.veiculo.imagem_lateral_e).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_lateral_e })
            })
          } else if (this.veiculo.imagem_lateral_d) {
            this.doesImageExist(this.veiculo.imagem_lateral_d).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_lateral_d })
            })
          } else if (this.veiculo.imagem_interior) {
            this.doesImageExist(this.veiculo.imagem_interior).then(resposta => {
              if (resposta) this.fotos.push({ url: this.veiculo.imagem_interior })
            })
          }
        }
        this.loading = false
      }
    }
  },
  methods: {
    abrirVisualizar (revenda) {
      localStorage.setItem('revenda', revenda.cnpj)
      this.$router.push({
        name: 'DetalhesDaRevenda',
        params: revenda
      })
    },
    async doesImageExist (url) {
      return new Promise((resolve) => {
        const img = new Image()
        img.src = url
        img.onload = () => resolve(true)
        img.onerror = () => resolve(false)
      })
    },
    getVeiculosApi (filtro) {
      return new Promise((resolve, reject) => {
        this.loading = true
        veiculoDao.get(this.headers, filtro).then(response => {
          this.loading = false
          resolve(response.json())
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    pegarVeiculos (id) {
      this.getVeiculosApi({ id_veiculo: id }).then(json => {
        this.veiculo = json.results[0]
      })
    }
  }
}
</script>

<style scoped>
  .font-aileron {
    font-family: 'Aileron', sans-serif !important;
    font-weight: 400;
    font-size: 1em;
  }
</style>
