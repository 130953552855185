<template>
  <v-card
    class="ma-4"
    :style="{
      'border': '0.3em solid #021e49 !important',
      'border-radius': '1em',
      'align-items': 'center',
      'justify-content': 'space-between',
      'width': card_width,
      'min-width': card_width,
      'min-height': '36em',
      'height': '36em',
    }"
  >
    <v-card-title class="primary white--text">
      <font-awesome-icon icon="building" class="white--text mr-2"/>
      {{ revenda.nome_fantasia }}
    </v-card-title>
    <v-layout justify-center class="mb-2">
      <img v-if="revenda.url_imagem_capa" :src="revenda.url_imagem_capa" style="width: 100%; max-height: 11em;">
      <v-card
        v-else
        style="
          width: 100%;
          height: 11em;
          border-radius: 0%;
          justify-content: center;
          align-items: center;
          display: flex;
        "
        color="transparent"
        elevation="0"
      >
        <font-awesome-icon class="fa-6x" :icon="['fas', 'image']" />
      </v-card>
    </v-layout>
    <v-card class="primary white--text ma-1 pa-2"  v-if="revenda.contato1">
      <font-awesome-icon :icon="['fas', 'square-phone']" class="fa-lg"/>
      {{ revenda.contato1 }}
    </v-card>
    <div v-else class="ma-1 pa-2 transparent--text">:)</div>
    <v-card-text class="primary--text">
      <h2 class="mb-2" v-if="revenda.endereco" style="height: 2.5em;">
        {{ revenda.endereco.logradouro }},{{ revenda.endereco.numero }}
      </h2>
      <h2 class="mb-2" v-if="revenda.endereco" style="height: 2.5em;">
        {{ revenda.endereco.bairro }}
      </h2>
      <h2 v-if="revenda.endereco" style="height: 2.5em;">
        {{ revenda.endereco.cidade }}
      </h2>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-space-around">
      <v-flex xs5>
        <v-btn
          color="primary"
          class="mt-2 mb-4"
          block
          :disabled="!revenda.contato1"
          @click="abrirWhatsApp"
        >
          Whatsapp
        </v-btn>
      </v-flex>
      <v-flex xs5>
        <v-btn
          color="primary"
          class="mt-2 mb-4"
          @click="$emit('visualizar')"
          block
        >
          Ver Veículos
        </v-btn>
      </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['revenda'],
  computed: {
    card_width () {
      if (this.$vuetify.breakpoint.xs) return '23em'
      else if (this.$vuetify.breakpoint.sm) return '23em'
      else if (this.$vuetify.breakpoint.md) return '23em'
      return '23em'
    }
  },
  methods: {
    abrirWhatsApp () {
      if (!this.revenda.contato1) {
        return
      }
      const telefoneLimpo = this.revenda.contato1.replace(/\D/g, '')
      const linkWhatsApp = `https://wa.me/55${telefoneLimpo}`
      window.open(linkWhatsApp, '_blank')
    }
  }
}
</script>

<style scoped>
  .v-card {
    font-family: 'Aileron', sans-serif !important;
    font-weight: 400;
  }
</style>
