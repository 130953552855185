import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/app/Home.vue'
import Revendas from './views/app/Revendas'
import Veiculos from './views/app/Veiculos'
import DetalhesVeiculo from './views/app/DetalhesVeiculo'
import DetalhesRevenda from './views/app/DetalhesRevenda.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '',
      component: Home,
      children: [
        {
          path: 'revendas',
          component: Revendas,
          name: 'ListaDeRevendas',
          props: { fullscreen: true }
        },
        {
          path: '',
          component: Veiculos,
          name: 'ListaDeVeiculos',
          props: { fullscreen: true }
        },
        {
          path: 'detalhes-do-veiculo',
          component: DetalhesVeiculo,
          props: { fullscreen: true },
          name: 'DetalhesDoVeiculo'
        },
        {
          path: 'detalhes-da-revenda',
          component: DetalhesRevenda,
          props: { fullscreen: true },
          name: 'DetalhesDaRevenda'
        }
      ]
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    }
  ]
})

export default router
