const API_IBGE = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados'

const pegarEstados = () => {
  return fetch(API_IBGE, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: {
      Accept: 'application/json'
    }
  })
}

const pegarCidades = (estadoSigla) => {
  return fetch(`${API_IBGE}/${estadoSigla}/municipios`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: {
      Accept: 'application/json'
    }
  })
}

export default {
  pegarEstados,
  pegarCidades
}
