<template>
  <v-layout
    align-space-around
    justify-start
    class="mt-4"
    style="width: 100%; height: 100%;"
    wrap
  >
    <v-toolbar color="primary" class="mb-4" style="width: 100%;">
      <v-btn
        icon
        absolute
        style="left: 1.5em;"
        @click="drawer = !drawer"
      >
        <v-icon color="white">tune</v-icon>
      </v-btn>
      <v-toolbar-title
        class="white--text"
        :style="{ 'font-size': $vuetify.breakpoint.xs ? '1.6em' : '2.4em' }"
      >
        Veículos Anunciados
      </v-toolbar-title>
    </v-toolbar>
    <v-card
      height="100%"
      class="pa-4 mb-4"
      v-show="drawer"
      :style="{
          'width': largura_card,
          'top': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '6.5em' : '0.5em',
          'height': altura_card,
          'min-height': altura_card
        }
      "
    >
      <v-card-text>
        <v-stepper
          v-model="step"
          class="pa-0 ma-0"
          style="box-shadow: none;"
        >
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-autocomplete
                label="Estado"
                :items="estados"
                v-model="filtro.estado"
                item-text="nome"
                item-value="sigla"
                @change="onEstadoChange"
                outlined
                :disabled="!!filtro.cidade"
                clearable
              ></v-autocomplete>

              <v-autocomplete
                label="Cidade"
                :items="cidades"
                v-model="filtro.cidade"
                item-text="nome"
                item-value="nome"
                :disabled="!filtro.estado"
                outlined
                clearable
              ></v-autocomplete>
              <v-divider></v-divider>

              <h4 class="mb-3 mt-3">Ano</h4>
              <v-layout justify-space-around>
                <v-flex xs5>
                  <v-text-field
                    label="De"
                    outlined
                    hint="Ex: 2012"
                    v-model="filtro.ano_fabricacao__de"
                    type="number"
                    v-mask="'NNNN'"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                    label="Até"
                    outlined
                    hint="Ex: 2020"
                    v-model="filtro.ano_fabricacao__ate"
                    type="number"
                    v-mask="'NNNN'"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Preço</h4>
              <v-layout justify-space-around>
                <v-flex xs5>
                  <v-text-field
                    label="De"
                    outlined
                    hint="Ex: 14.000,00"
                    v-model="filtro.valor__de"
                    prefix="R$"
                    type="number"
                    :min="0"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                    label="Até"
                    outlined
                    hint="Ex: 65.000,00"
                    v-model="filtro.valor__ate"
                    prefix="R$"
                    type="number"
                    :min="0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>

              <h4 class="mb-3 mt-3">Quilometragem</h4>
              <v-layout justify-space-around>
                <v-flex xs5>
                  <v-text-field
                    label="De"
                    outlined
                    hint="Ex: 15.000"
                    v-model="filtro.quilometragem__de"
                    type="number"
                    :min="0"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                    label="Até"
                    outlined
                    hint="Ex: 25.000"
                    v-model="filtro.quilometragem__ate"
                    type="number"
                    :min="0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Carro</h4>
              <v-checkbox
                label="Novo"
                @change="toggleUsado(false)"
                :value="false in filtro.usado__in"
              ></v-checkbox>
              <v-checkbox
                label="Usado"
                @change="toggleUsado(true)"
                :value="true in filtro.usado__in"
              ></v-checkbox>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Marca</h4>
              <v-autocomplete
                outlined
                clearable
                :items="marcas"
                label="Selecione uma marca"
                v-model="filtro.marca"
              ></v-autocomplete>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Modelo</h4>
              <v-text-field
                outlined
                clearable
                label="Digite o modelo"
                v-model="filtro.modelo"
              ></v-text-field>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Quantidade de portas</h4>
              <v-autocomplete
                outlined
                clearable
                :items="[
                  1, 2, 3, 4, 5, 6
                ]"
                label="Digite a quantidade desejada"
                v-model="filtro.qtd_portas"
              ></v-autocomplete>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Cor</h4>
              <template v-for="(cor, index) in cores">
                <v-checkbox
                  :key="`${index}cor`"
                  :label="cor"
                  :value="cor.toUpperCase() in filtro.cores"
                  @click="toggleCor(cor.toUpperCase())"
                  v-if="index < 4">
                </v-checkbox>
              </template>
              <v-btn text small block @click="step = 2" class="mb-4">
                Motrar todas as cores
                <v-icon class="ml-1">
                  keyboard_arrow_right
                </v-icon>
              </v-btn>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Câmbio</h4>
              <v-checkbox
                class="mt-3"
                v-for="(cambio, index) in cambios"
                :key="`${index}cambio`"
                :label="cambio.texto"
                :value="cambio.valor in filtro.cambios"
                @click="toggleCambio(cambio.valor)"
              ></v-checkbox>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Direção</h4>
              <v-checkbox
                v-for="(direcao, index) in direcoes"
                :key="`${index}direcao`"
                :label="direcao.texto"
                :value="direcao.valor in filtro.direcoes"
                @click="toggleDirecao(direcao.valor)"
              ></v-checkbox>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Combustíveis</h4>
              <v-checkbox
                v-for="(combustivel, index) in combustiveis"
                :key="`${index}combustivel`"
                :label="combustivel.text"
                :value="combustivel.value in filtro.combustiveis"
                @click="toggleCombustivel(combustivel.value)"
              ></v-checkbox>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Opcionais</h4>
              <template v-for="(opcional, index) in opcionais">
                <v-checkbox
                  :key="`${index}opcional`"
                  :label="opcional.nome"
                  v-if="expandirOpcionais || (!expandirOpcionais && index < 4)"
                  :value="opcional.codigo in filtro.opcionais"
                  @click="toggleOpcinal(opcional.codigo)"
                >
                </v-checkbox>
              </template>
              <v-btn text small block @click="step = 3" class="mb-4">
                Motrar todas os opcionais
                <v-icon class="ml-1">
                  keyboard_arrow_right
                </v-icon>
              </v-btn>
              <v-divider></v-divider>
              <h4 class="mb-3 mt-3">Características</h4>
              <v-checkbox
                label="IPVA Pago"
                v-model="filtro.ipva_pago"
              />
              <v-checkbox
                label="Único Dono"
                v-model="filtro.unico_dono"
              />
              <v-checkbox
                label="Com Manual"
                v-model="filtro.com_manual"
              />
              <v-checkbox
                label="Com Garantia"
                v-model="filtro.com_garantia"
              />
              <v-checkbox
                label="Revisões feitas em concessionária"
                v-model="filtro.revisoes_feitas"
              />
              <v-checkbox
                label="Chave Reserva"
                v-model="filtro.chave_reserva"
              />
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-btn text small block @click="step = 1" class="mb-4">
                <v-icon class="ml-1">
                  keyboard_arrow_left
                </v-icon>
                Voltar
              </v-btn>
              <h4 class="mb-3 mt-3">Cor</h4>
              <template v-for="(cor, index) in cores">
                <v-checkbox
                  :key="`${index}-cor`"
                  :label="cor"
                  :value="cor.toUpperCase() in filtro.cores"
                  @click="toggleCor(cor.toUpperCase())"
                >
                </v-checkbox>
              </template>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-btn text small block @click="step = 1" class="mb-4">
                <v-icon class="ml-1">
                  keyboard_arrow_left
                </v-icon>
                Voltar
              </v-btn>
              <h4 class="mb-3 mt-3">Opcionais</h4>
              <template v-for="(opcional, index) in opcionais">
                <v-checkbox
                  :key="`${index}-opcional`"
                  :label="opcional.nome"
                  :value="opcional.codigo in filtro.opcionais"
                  @click="toggleOpcinal(opcional.codigo)"
                >
                </v-checkbox>
              </template>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
    <v-container
      :style="{ 'width': largura_container, 'margin-bottom': '10em' }"
      v-if="(($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) && !drawer) || !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
    >
      <template v-if="!loading">
        <v-layout
          align-start
          :justify-start="!$vuetify.breakpoint.xs"
          :justify-center="$vuetify.breakpoint.xs"
          row
          wrap
          style="width: 100%;"
          >
          <v-flex
            xs12
            :sm6="!drawer"
            :sm12="drawer"
            :md4="!drawer"
            :md6="drawer"
            lg4
            xl3
            v-for="(veiculo, index) in veiculos"
            :key="index"
            class="mb-3 d-flex"
            style="justify-content: center;"
          >
            <card-veiculo
              :veiculo="veiculo"
              @visualizar="abrirVisualizar(veiculo)"
            />
            </v-flex>
        </v-layout>
        <v-pagination v-model="pagination.page" :length="pages" class="mb-4"></v-pagination>
      </template>
      <v-layout
        v-else
        align-center
        justify-center
        style="width: 100%; height: 100%;"
        class="text-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="300"
        ></v-progress-circular>
      </v-layout>
    </v-container>
    <v-container v-else style="width: 100%; height: 100%;" class="transparent--text">
      Teste
    </v-container>
    <Footer></Footer>
  </v-layout>
</template>
<script>
import { veiculoDao, ibge } from '@/api'
import CardVeiculo from '@/components/CardVeiculo.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: { CardVeiculo, Footer },
  data: () => ({
    step: 1,
    loading: false,
    drawer: true,
    veiculos: [],
    totalItens: 0,
    expandirCor: false,
    expandirOpcionais: false,
    expandirCaracteristicas: false,
    valor: undefined,
    filtro: {
      estado: undefined,
      cidade: undefined,
      marca: undefined,
      modelo: undefined,
      ano_fabricacao__de: undefined,
      ano_fabricacao__ate: undefined,
      quilometragem__de: undefined,
      quilometragem__ate: undefined,
      valor__de: undefined,
      valor__ate: undefined,
      usado__in: [],
      cambios: [],
      direcoes: [],
      cores: [],
      combustiveis: [],
      opcionais: [],
      tipo_veiculo__denominacao__in: [],
      ipva_pago: false,
      unico_dono: false,
      com_manual: false,
      com_garantia: false,
      revisoes_feitas: false,
      chave_reserva: false,
      qtd_portas: undefined
    },
    estados: [],
    cidades: [],
    cambios: [
      { texto: 'Manual', valor: 1 },
      { texto: 'Automático', valor: 2 },
      { texto: 'Automatizado', valor: 3 },
      { texto: 'CVT', valor: 4 }
    ],
    direcoes: [
      { texto: 'Mecânica', valor: 1 },
      { texto: 'Hidráulica', valor: 2 },
      { texto: 'Elétrica', valor: 3 },
      { texto: 'Eletro-hidráulica', valor: 4 }
    ],
    cores: [
      'Preta',
      'Prata',
      'Branca',
      'Vermelha',
      'Cinza',
      'Azul',
      'Beje',
      'Verde',
      'Bronze',
      'Amarela',
      'Dourada',
      'Vinho',
      'Marrom',
      'Rosa',
      'Roxa',
      'Laranja'
    ],
    marcas: [
      'Agrale',
      'Aston Martin',
      'Audi',
      'Avelloz',
      'Bentley',
      'BMW',
      'BMW Motorrad',
      'BYD',
      'Chery',
      'Chevrolet',
      'Chrysler',
      'Citroen',
      'Dafra',
      'Dodge',
      'Ducati',
      'Effa',
      'Exeed',
      'Ferrari',
      'Fiat',
      'Ford',
      'Foton',
      'GM',
      'Haojue',
      'Harley Davidson',
      'Honda',
      'Husqvarna',
      'Hyundai',
      'Iveco',
      'JAC',
      'Jaguar',
      'Jeep',
      'Kasinski',
      'KTM',
      'Kawasaki',
      'Kia',
      'Kymco',
      'Lamborghini',
      'Land Rover',
      'Lexus',
      'Lifan',
      'Maserati',
      'McLaren',
      'Mercedes-AMG',
      'Mercedes-Benz',
      'Mini',
      'Mitsubishi',
      'MXF',
      'Nissan',
      'Outra',
      'Peugeot',
      'Piaggio',
      'Porsche',
      'Pro Tork',
      'RAM',
      'Renault',
      'Rolls-Royce',
      'Royal Enfield',
      'Shineray',
      'Smart',
      'Sousa Motos',
      'Subaru',
      'Sundown',
      'Suzuki',
      'Toyota',
      'Triumph',
      'Troller',
      'Volkswagen',
      'Volvo',
      'Voltz Motors',
      'Yamaha'
    ],
    opcionais: [],
    combustiveis: [
      { text: 'Gasolina', value: 4 },
      { text: 'Álcool', value: 5 },
      { text: 'Diesel', value: 6 },
      { text: 'Gás natural', value: 7 }
    ],
    pagination: {
      page: 1,
      rowsPerPage: 48
    },
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  }),
  mounted () {
    this.fetchEstados()
  },
  beforeMount () {
    if (this.$vuetify.breakpoint.xl) {
      this.pagination.rowsPerPage = 48
    }
    if (this.$vuetify.breakpoint.lg) {
      this.pagination.rowsPerPage = 36
    }
    if (this.$vuetify.breakpoint.md) {
      this.pagination.rowsPerPage = 24
    }
    if (this.$vuetify.breakpoint.sm) {
      this.drawer = false
      this.pagination.rowsPerPage = 12
    }
    if (this.$vuetify.breakpoint.xs) {
      this.drawer = false
      this.pagination.rowsPerPage = 6
    }
    this.pegarVeiculos()
    this.pegarOpcionais()
  },
  watch: {
    'pagination.page' (val) {
      if (val) this.pegarVeiculos()
    },
    filtros () {
      this.pegarVeiculos()
    }
  },
  computed: {
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0
      return Math.ceil(count / this.pagination.rowsPerPage)
    },
    filtros () {
      const data = {
        page: this.pagination.page,
        page_size: this.pagination.rowsPerPage
      }
      if (this.filtro.estado) {
        data.empresa__endereco__estado__icontains = this.filtro.estado
      }
      if (this.filtro.cidade) {
        data.empresa__endereco__cidade__icontains = this.filtro.cidade
      }
      if (this.filtro.marca) {
        data.marca__icontains = this.filtro.marca
      }
      if (this.filtro.modelo) {
        data.modelo__icontains = this.filtro.modelo
      }
      if (this.filtro.ano_fabricacao__de) {
        data.ano_fabricacao__gte = this.filtro.ano_fabricacao__de
      }
      if (this.filtro.ano_fabricacao__ate) {
        data.ano_fabricacao__lte = this.filtro.ano_fabricacao__ate
      }
      if (this.filtro.quilometragem__de) {
        data.quilometragem__gte = this.filtro.quilometragem__de
      }
      if (this.filtro.quilometragem__ate) {
        data.quilometragem__lte = this.filtro.quilometragem__ate
      }
      if (this.filtro.valor__de) {
        data.valor__gte = this.filtro.valor__de
      }
      if (this.filtro.valor__ate) {
        data.valor__lte = this.filtro.valor__ate
      }
      if (this.filtro.usado__in.length > 0) {
        data.usado__in = this.filtro.usado__in
      }
      if (this.filtro.cambios.length > 0) {
        data.cambio__in = this.filtro.cambios
      }
      if (this.filtro.direcoes.length > 0) {
        data.direcao__in = this.filtro.direcoes
      }
      if (this.filtro.cores.length > 0) {
        data.cor_texto__in = this.filtro.cores
      }
      if (this.filtro.combustiveis.length > 0) {
        data.veiculo_combustivel__combustivel__in = this.filtro.combustiveis
      }
      if (this.filtro.opcionais.length > 0) {
        data.opcionais_veiculo__opcional__codigo__in = this.filtro.opcionais
      }
      if (this.filtro.tipo_veiculo__denominacao__in.length > 0) {
        data.tipo_veiculo__denominacao__in = this.filtro.tipo_veiculo__denominacao__in
      }
      if (this.filtro.ipva_pago) {
        data.ipva_pago = this.filtro.ipva_pago
      }
      if (this.filtro.unico_dono) {
        data.unico_dono = this.filtro.unico_dono
      }
      if (this.filtro.com_manual) {
        data.com_manual = this.filtro.com_manual
      }
      if (this.filtro.com_garantia) {
        data.com_garantia = this.filtro.com_garantia
      }
      if (this.filtro.revisoes_feitas) {
        data.revisoes_feitas = this.filtro.revisoes_feitas
      }
      if (this.filtro.chave_reserva) {
        data.chave_reserva = this.filtro.chave_reserva
      }
      if (this.filtro.qtd_portas) {
        data.qtd_portas = this.filtro.qtd_portas
      }
      return data
    },
    largura_card () {
      if (!this.drawer) return '0%'
      if (this.$vuetify.breakpoint.xl) return '17%'
      if (this.$vuetify.breakpoint.lg) return '25%'
      if (this.$vuetify.breakpoint.md) return '35%'
      if (this.$vuetify.breakpoint.sm) return '100%'
      if (this.$vuetify.breakpoint.xs) return '100%'
      return '17%'
    },
    altura_card () {
      if (!this.drawer) return '0%'
      if (this.$vuetify.breakpoint.xl) return '202em'
      if (this.$vuetify.breakpoint.lg) return '202m'
      if (this.$vuetify.breakpoint.md) return '202em'
      if (this.$vuetify.breakpoint.sm) return '202em'
      if (this.$vuetify.breakpoint.xs) return '202em'
      return '100%'
    },
    largura_container () {
      if (!this.drawer) return '100%'
      if (this.$vuetify.breakpoint.xl) return '83%'
      if (this.$vuetify.breakpoint.lg) return '75%'
      if (this.$vuetify.breakpoint.md) return '65%'
      if (this.$vuetify.breakpoint.sm) return '50%'
      if (this.$vuetify.breakpoint.xs) return '100%'
      return '100%'
    }
  },
  methods: {
    async fetchEstados () {
      try {
        ibge.pegarEstados().then(response => {
          return response.json()
        }).then(json => {
          this.estados = json
        })
      } catch (error) {
        console.error('Erro ao buscar estados:', error)
      }
    },
    async onEstadoChange (estadoSigla) {
      if (estadoSigla) {
        try {
          ibge.pegarCidades(estadoSigla).then(response => {
            return response.json()
          }).then(json => {
            this.cidades = json
          })
        } catch (error) {
          console.error('Erro ao buscar cidades:', error)
        }
      } else {
        this.cidades = []
      }
    },
    abrirVisualizar (veiculo) {
      localStorage.setItem('veiculo', veiculo.id_veiculo)
      this.$router.push({
        name: 'DetalhesDoVeiculo',
        params: veiculo
      })
    },
    getVeiculosApi (filtro) {
      return new Promise((resolve, reject) => {
        this.loading = true
        veiculoDao.get(this.headers, filtro).then(response => {
          this.loading = false
          resolve(response.json())
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    pegarVeiculos () {
      this.getVeiculosApi(this.filtros).then(json => {
        this.veiculos = json.results
        this.totalItens = json.count
      })
    },
    getOpcionaisApi () {
      return new Promise((resolve, reject) => {
        this.loading = true
        veiculoDao.opcionais(this.headers).then(response => {
          this.loading = false
          resolve(response.json())
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    pegarOpcionais () {
      this.getOpcionaisApi().then(json => {
        this.opcionais = json
      })
    },
    toggleCor (cor) {
      if (this.filtro.cores.includes(cor)) {
        this.filtro.cores = this.filtro.cores.filter(c => c !== cor)
      } else {
        this.filtro.cores.push(cor)
      }
    },
    toggleCambio (cambio) {
      if (this.filtro.cambios.includes(cambio)) {
        this.filtro.cambios = this.filtro.cambios.filter(c => c !== cambio)
      } else {
        this.filtro.cambios.push(cambio)
      }
    },
    toggleDirecao (direcao) {
      if (this.filtro.direcoes.includes(direcao)) {
        this.filtro.direcoes = this.filtro.direcoes.filter(d => d !== direcao)
      } else {
        this.filtro.direcoes.push(direcao)
      }
    },
    toggleCombustivel (combustivel) {
      if (this.filtro.combustiveis.includes(combustivel)) {
        this.filtro.combustiveis = this.filtro.combustiveis.filter(c => c !== combustivel)
      } else {
        this.filtro.combustiveis.push(combustivel)
      }
    },
    toggleOpcinal (opcional) {
      if (this.filtro.opcionais.includes(opcional)) {
        this.filtro.opcionais = this.filtro.opcionais.filter(o => o !== opcional)
      } else {
        this.filtro.opcionais.push(opcional)
      }
    },
    toggleUsado (usado) {
      if (this.filtro.usado__in.includes(usado)) {
        this.filtro.usado__in = this.filtro.usado__in.filter(u => u !== usado)
      } else {
        this.filtro.usado__in.push(usado)
      }
    }
  }
}
</script>

<style scoped>
  h1 {
    font-family: 'harabara', sans-serif;
    font-weight: 900;
  }
</style>
