<template>
  <v-layout
    align-space-around
    justify-start
    class="mt-4"
    style="width: 100%; height: 100%;"
    wrap
  >
  <v-toolbar color="primary" class="mb-4" style="width: 100%;">
    <v-btn
      icon
      absolute
      style="left: 1.5em;"
      @click="drawer = !drawer"
    >
      <v-icon color="white">tune</v-icon>
    </v-btn>
    <v-toolbar-title
      class="white--text"
      :style="{ 'font-size': $vuetify.breakpoint.xs ? '1.6em' : '2.4em' }"
    >
      Vendedores Participantes
    </v-toolbar-title>
  </v-toolbar>
  <v-card
    class="pa-4 mb-4"
    v-show="drawer"
    :style="{
      'width': largura_card,
      'top': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '6.5em' : '0.5em',
      'height': altura_card,
      'margin-bottom': '10em'
    }"
  >
      <v-card-text>
        <h4 class="mb-3 mt-3">Localização</h4>
        <v-autocomplete
          label="Estado"
          :items="estados"
          v-model="filtro.estado"
          item-text="nome"
          item-value="sigla"
          @change="onEstadoChange"
          class="mr-2"
          outlined
          :disabled="!!filtro.cidade"
          clearable
        ></v-autocomplete>

        <v-autocomplete
          label="Cidade"
          :items="cidades"
          v-model="filtro.cidade"
          item-text="nome"
          item-value="nome"
          :disabled="!filtro.estado"
          outlined
          clearable
        ></v-autocomplete>
        <v-divider></v-divider>
        <h4 class="mb-3 mt-3">Nome da Revenda</h4>
        <v-text-field
          outlined
          clearable
          label="Digite o Nome da Revenda"
          v-model="filtro.nome_revenda"
        ></v-text-field>
      </v-card-text>
  </v-card>

  <v-container
    :style="{ 'width': largura_container, 'margin-bottom': '10em' }"
    v-if="(($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) && !drawer) || !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
  >
    <template v-if="!loading">
      <v-layout
        align-space-around
        justify-start
        column
        class="mt-4"
        style="width: 100%;"
      >
          <v-layout
            align-start
            :justify-start="!$vuetify.breakpoint.xs"
            :justify-center="$vuetify.breakpoint.xs"
            row
            wrap
            style="width: 100%;"
            class="ma-0 pa-0"
          >
            <v-flex
              xs12
              :sm6="!drawer"
              :sm12="drawer"
              :md6="!drawer"
              :md12="drawer"
              :lg4="!drawer"
              :lg6="drawer"
              :xl3="!drawer"
              :xl4="drawer"
              v-for="(revenda, index) in revendas"
              :key="index"
              class="mb-3 d-flex"
              style="justify-content: center;"
              >
              <card-revenda
                :revenda="revenda"
                @visualizar="abrirVisualizar(revenda)"
                style="height: 55vh;"
              />
            </v-flex>
          </v-layout>
          <v-pagination v-model="pagination.page" :length="pages" class="mb-4"></v-pagination>
      </v-layout>
    </template>
    <v-layout
      v-else
      align-center
      justify-center
      style="width: 100%; height: 100%;"
      class="text-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="300"
      ></v-progress-circular>
    </v-layout>
  </v-container>
  <Footer></Footer>
  </v-layout>
</template>
<script>
import { empresasDao, ibge } from '@/api'
import CardRevenda from '@/components/CardRevenda.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: { CardRevenda, Footer },
  data: () => ({
    step: 1,
    drawer: true,
    loading: false,
    revendas: [],
    totalItens: 0,
    filtro: {
      estado: undefined,
      cidade: undefined,
      nome_revenda: undefined
    },
    estados: [],
    cidades: [],
    pagination: {
      page: 1,
      rowsPerPage: 12
    },
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  }),
  mounted () {
    this.fetchEstados()
  },
  beforeMount () {
    if (this.$vuetify.breakpoint.sm) {
      this.drawer = false
      this.pagination.rowsPerPage = 12
    }
    if (this.$vuetify.breakpoint.xs) {
      this.drawer = false
      this.pagination.rowsPerPage = 6
    }
    this.pegarRevendas()
  },
  watch: {
    'pagination.page' (val) {
      if (val) this.pegarRevendas()
    },
    filtros () {
      this.pegarRevendas()
    }
  },
  computed: {
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0
      return Math.ceil(count / this.pagination.rowsPerPage)
    },
    filtros () {
      const data = {
        page: this.pagination.page,
        page_size: this.pagination.rowsPerPage
      }
      if (this.filtro.estado) {
        data.endereco__estado__icontains = this.filtro.estado
      }
      if (this.filtro.cidade) {
        data.endereco__cidade__icontains = this.filtro.cidade
      }
      if (this.filtro.nome_revenda) {
        data.nome_fantasia__icontains = this.filtro.nome_revenda
      }
      return data
    },
    largura_container () {
      if (!this.drawer) return '100%'
      if (this.$vuetify.breakpoint.xl) return '83%'
      if (this.$vuetify.breakpoint.lg) return '75%'
      if (this.$vuetify.breakpoint.md) return '65%'
      if (this.$vuetify.breakpoint.sm) return '50%'
      if (this.$vuetify.breakpoint.xs) return '100%'
      return '100%'
    },
    largura_card () {
      if (!this.drawer) return '0%'
      if (this.$vuetify.breakpoint.xl) return '17%'
      if (this.$vuetify.breakpoint.lg) return '25%'
      if (this.$vuetify.breakpoint.md) return '35%'
      if (this.$vuetify.breakpoint.sm) return '100%'
      if (this.$vuetify.breakpoint.xs) return '100%'
      return '17%'
    },
    altura_card () {
      if (!this.drawer) return '0%'
      if (this.$vuetify.breakpoint.xl) return '25em'
      if (this.$vuetify.breakpoint.lg) return '25em'
      if (this.$vuetify.breakpoint.md) return '25em'
      if (this.$vuetify.breakpoint.sm) return '30em'
      if (this.$vuetify.breakpoint.xs) return '30em'
      return '100%'
    }
  },
  methods: {
    async fetchEstados () {
      try {
        ibge.pegarEstados().then(response => {
          return response.json()
        }).then(json => {
          this.estados = json
        })
      } catch (error) {
        console.error('Erro ao buscar estados:', error)
      }
    },
    async onEstadoChange (estadoSigla) {
      if (estadoSigla) {
        try {
          ibge.pegarCidades(estadoSigla).then(response => {
            return response.json()
          }).then(json => {
            this.cidades = json
          })
        } catch (error) {
          console.error('Erro ao buscar cidades:', error)
        }
      } else {
        this.cidades = []
      }
    },
    abrirVisualizar (revenda) {
      localStorage.setItem('revenda', revenda.cnpj)
      this.$router.push({
        name: 'DetalhesDaRevenda',
        params: revenda
      })
    },
    getRevendasApi (filtro) {
      return new Promise((resolve, reject) => {
        this.loading = true
        empresasDao.get(this.headers, filtro).then(response => {
          this.loading = false
          resolve(response.json())
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    pegarRevendas () {
      this.getRevendasApi(this.filtros).then(json => {
        this.revendas = json.results
        this.totalItens = json.count
      })
    }
  }
}
</script>
