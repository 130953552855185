var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4",style:({
    'border': '0.3em solid #021e49 !important',
    'border-radius': '1em',
    'align-items': 'center',
    'justify-content': 'space-between',
    'width': _vm.card_width,
    'min-width': _vm.card_width,
    'min-height': '36em',
    'height': '36em',
  })},[_c('v-card-title',{staticClass:"primary white--text"},[_c('font-awesome-icon',{staticClass:"white--text mr-2",attrs:{"icon":"building"}}),_vm._v(" "+_vm._s(_vm.revenda.nome_fantasia)+" ")],1),_c('v-layout',{staticClass:"mb-2",attrs:{"justify-center":""}},[(_vm.revenda.url_imagem_capa)?_c('img',{staticStyle:{"width":"100%","max-height":"11em"},attrs:{"src":_vm.revenda.url_imagem_capa}}):_c('v-card',{staticStyle:{"width":"100%","height":"11em","border-radius":"0%","justify-content":"center","align-items":"center","display":"flex"},attrs:{"color":"transparent","elevation":"0"}},[_c('font-awesome-icon',{staticClass:"fa-6x",attrs:{"icon":['fas', 'image']}})],1)],1),(_vm.revenda.contato1)?_c('v-card',{staticClass:"primary white--text ma-1 pa-2"},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":['fas', 'square-phone']}}),_vm._v(" "+_vm._s(_vm.revenda.contato1)+" ")],1):_c('div',{staticClass:"ma-1 pa-2 transparent--text"},[_vm._v(":)")]),_c('v-card-text',{staticClass:"primary--text"},[(_vm.revenda.endereco)?_c('h2',{staticClass:"mb-2",staticStyle:{"height":"2.5em"}},[_vm._v(" "+_vm._s(_vm.revenda.endereco.logradouro)+","+_vm._s(_vm.revenda.endereco.numero)+" ")]):_vm._e(),(_vm.revenda.endereco)?_c('h2',{staticClass:"mb-2",staticStyle:{"height":"2.5em"}},[_vm._v(" "+_vm._s(_vm.revenda.endereco.bairro)+" ")]):_vm._e(),(_vm.revenda.endereco)?_c('h2',{staticStyle:{"height":"2.5em"}},[_vm._v(" "+_vm._s(_vm.revenda.endereco.cidade)+" ")]):_vm._e()]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-flex',{attrs:{"xs5":""}},[_c('v-btn',{staticClass:"mt-2 mb-4",attrs:{"color":"primary","block":"","disabled":!_vm.revenda.contato1},on:{"click":_vm.abrirWhatsApp}},[_vm._v(" Whatsapp ")])],1),_c('v-flex',{attrs:{"xs5":""}},[_c('v-btn',{staticClass:"mt-2 mb-4",attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.$emit('visualizar')}}},[_vm._v(" Ver Veículos ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }