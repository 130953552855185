var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"elevation-8 pa-0 ma-0",attrs:{"color":"#fff","height":_vm.$vuetify.breakpoint.xs ? _vm.menu ? '580em' : '380em' : '200em',"dense":"","shrink-on-scroll":""}},[_c('v-layout',{staticClass:"black ma-0",style:({
    'width': '100%',
    'height': _vm.$vuetify.breakpoint.xs ? _vm.menu ? '42%' : '10%' : '22%',
    'align-items': 'center',
    'display': 'flex'
  }),attrs:{"row":"","wrap":"","justify-end":!_vm.$vuetify.breakpoint.xs}},[(!_vm.$vuetify.breakpoint.xs)?[_c('v-btn',{staticClass:"links",attrs:{"text":"","color":"secontary","dark":"","small":"","href":"https://conectarevendas.com.br/","target":"_blank"}},[_vm._v(" Página Inicial ")]),_c('v-btn',{staticClass:"links",attrs:{"text":"","color":"secontary","dark":"","small":"","href":"https://conectarevendas.com.br/blog/","target":"_blank"}},[_vm._v(" Blog ")]),_c('v-btn',{staticClass:"links",attrs:{"text":"","color":"secontary","dark":"","small":"","href":"https://conectarevendas.com.br/duvidas/","target":"_blank"}},[_vm._v(" Perguntas Frequentes ")]),_c('v-btn',{staticClass:"links",attrs:{"text":"","color":"secontary","dark":"","small":"","href":"https://conectarevendas.com.br/contato/","target":"_blank"}},[_vm._v(" Contato ")])]:[_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){_vm.menu = !_vm.menu}}},[_c('v-icon',[_vm._v("menu")])],1),(_vm.menu)?[_c('v-list',{staticClass:"black",staticStyle:{"width":"100%"},attrs:{"dark":""}},[_c('v-list-item',{attrs:{"href":"https://conectarevendas.com.br/"}},[_c('v-list-item-title',{staticClass:"item-lista"},[_vm._v(" Página Inicial ")])],1),_c('v-list-item',{attrs:{"href":"https://conectarevendas.com.br/blog/"}},[_c('v-list-item-title',{staticClass:"item-lista"},[_vm._v(" Blog ")])],1),_c('v-list-item',{attrs:{"href":"https://conectarevendas.com.br/duvidas/"}},[_c('v-list-item-title',{staticClass:"item-lista"},[_vm._v(" Perguntas Frequentes ")])],1),_c('v-list-item',{attrs:{"href":"https://conectarevendas.com.br/contato/"}},[_c('v-list-item-title',{staticClass:"item-lista"},[_vm._v(" Contato ")])],1)],1)]:_vm._e()]],2),_c('v-layout',{style:({
    'width':' 100%',
    'height':  _vm.menu ? '58%' : '78%'
  }),attrs:{"justify-space-between":"","wrap":""}},[_c('v-flex',{staticClass:"mb-4 allcenter",attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-img',{attrs:{"src":require('@/assets/logo01.png'),"contain":"","height":_vm.$vuetify.breakpoint.xl ? '90%' : '100%'}})],1),_c('v-flex',{staticClass:"allcenter mt-4",attrs:{"xs12":"","sm8":"","md9":""}},[_c('v-layout',{attrs:{"justify-space-around":"","wrap":""}},[_c('v-flex',{staticClass:"mb-4 allcenter",attrs:{"xs12":"","sm5":""}},[_c('v-btn',{staticClass:"botao",attrs:{"color":"primary","block":"","large":!_vm.$vuetify.breakpoint.sm,"small":_vm.$vuetify.breakpoint.sm,"href":"https://conectarevendas.com.br/login","target":"_blank"}},[_vm._v(" Cadastre seu veículo ")])],1),_c('v-flex',{staticClass:"mb-4 allcenter",attrs:{"xs12":"","sm5":""}},[_c('v-btn',{staticClass:"botao",attrs:{"color":"primary","block":"","large":!_vm.$vuetify.breakpoint.sm,"small":_vm.$vuetify.breakpoint.sm,"href":"https://conectarevendas.com.br/formulario-para-revenda/","target":"_blank"}},[_vm._v(" Cadastre sua revenda ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }