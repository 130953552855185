import Vue from 'vue'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css' // Re-uses images from ~leaflet package
import 'leaflet'
import 'leaflet-defaulticon-compatibility'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from './plugins/vuetify'
import SvgFiller from 'vue-svg-filler'
import moment from 'moment'
import VueCurrencyFilter from 'vue-currency-filter'
import { VueMaskDirective } from 'v-mask'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(fas)
library.add(far)
library.add(fab)

moment.locale('pt-br')

Vue.config.productionTip = false
Vue.use(VueCurrencyFilter,
  {
    symbol: 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })
Vue.filter('formatDate', (value, format) => {
  if (value) {
    return moment(String(value)).format(format)
  }
})

Vue.component('svg-filler', SvgFiller)
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

// Código principal

// Código da diretiva
Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

// Para usar mascara
Vue.directive('mask', VueMaskDirective)

Vue.component('font-awesome-icon', FontAwesomeIcon)
