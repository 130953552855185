import settings from '@/settings'

const get = (headers, filtros) => {
  // Mostra Veículos avenda ou veículos da empresa para vender ou trocar
  let url = `${settings.apetrusApi}/veiculos/aberto/`
  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${filtros[keys[i]]}`
    }
  }

  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const opcionais = (headers) => {
  return fetch(`${settings.apetrusApi}/veiculos/opcionais/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  get,
  opcionais
}
